import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import VideoListView from '../views/VideoListView.vue'
import VideoInfo from '../views/VideoInfo.vue'
import AboutView from '../views/AboutView.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    }

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/VideoListView',
    name: 'VideoListView',
    component: VideoListView
  },
  {
    path: '/VideoInfo',
    name: 'VideoInfo',
    component: VideoInfo
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    component: AboutView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
