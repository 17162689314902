//axios
import axios from 'axios'
import C from './cache'
import cookie from 'vue-cookies';
//axios
var debug = false;
function fn() { }
var cRed = 'color:red;', cGreen = 'color:green;', cFont = 'font-family:DroidSans;';
var l = ' ========= ';
var str_start = l + 'Debug Start' + l, str_success = l + 'Debug Success End' + l, str_error = l + 'Debug Error End' + l;
function m_log(str, strle) {
  console.log('%c' + str, strle);
}
export function fetch(url, params, success = fn, error = fn, method = 'post') {
  if (debug) {
    m_log(str_start, cGreen + cFont);
    m_log('url:[' + method + '] ' + url, cGreen + cFont);
    m_log('params: ' + JSON.stringify(params), cGreen + cFont);
  }
  let conf = { method: method };
  let headers = {};
  if (method == 'get') {
    let pms = [];
    if (!chkEmptyObj(params)) {
      for (var k in params) { pms.push(k + '=' + params[k]) }
      url = url.indexOf('?') > 0 ? url + pms.join('&') : url + '?' + pms.join('&');
    }
  } else if (method == 'upload') {
    conf.method = 'post';
    var formData = new FormData();
    formData.append('file', params.file);
    conf.data = formData
    headers = { 'Content-Type': 'multipart/form-data' }
  } else if (method == 'post') {
    var formData = new FormData()
    for (var key in params) {
      formData.append(key, params[key])
    }
    conf.data = formData
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
  }
  headers.token = cookie.get('ddwg_token');

  // C.checkCache('token', (token) => { headers.token = token }, (err) => { })

  conf.headers = headers

  if (method == 'upload') {
    // conf.url = 'http://localhost/ddwg/public/addons/alioss/index/upload';
    conf.url = 'https://p.xilukeji.com/haiwai/public/index.php/addons/alioss/index/upload'
  } else {
    // 
    if (process.env.NODE_ENV == 'development') {
      conf.url = 'https://p.xilukeji.com/haiwai/public/index.php' + url;
    } else if (process.env.NODE_ENV == 'production') {
      conf.url = 'https://p.xilukeji.com/haiwai/public/index.php' + url;
    }
  }


  axios(conf)
    .then(response => {
      debug ? m_log(str_success, cGreen + cFont) : {};
      '/common/oauth/user' == url ? C.setCache('token', response.data.data) : {};
      '/user/profile' == url ? C.setCache('token', response.data.data) : {};
      '/user/profile/update' == url;
      success ? success(response.data) : m_log(response, cRed + cFont);
    })

    .catch(err => {
      debug ? m_log(str_error, cRed + cFont) : {};

      error ? error(err) : console.error(err);
    });
}
export function fetch2(url, params, success = fn, error = fn, method = 'post') {
  if (debug) {
    m_log(str_start, cGreen + cFont);
    m_log('url:[' + method + '] ' + url, cGreen + cFont);
    m_log('params: ' + JSON.stringify(params), cGreen + cFont);
  }
  let conf = { method: method };
  let headers = {};
  if (method == 'get') {
    let pms = [];
    if (!chkEmptyObj(params)) {
      for (var k in params) { pms.push(k + '=' + params[k]) }
      url = url.indexOf('?') > 0 ? url + pms.join('&') : url + '?' + pms.join('&');
    }
  } else if (method == 'upload') {
    conf.method = 'post';
    var formData = new FormData();
    formData.append('file', params.file);
    conf.data = formData
    headers = { 'Content-Type': 'multipart/form-data' }
  } else if (method == 'post') {
    var formData = new FormData()
    for (var key in params) {
      formData.append(key, params[key])
    }
    conf.data = formData
    headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
  }
  // headers.token = cookie.get('ddwg_token');

  // C.checkCache('token', (token) => { headers.token = token }, (err) => { })

  conf.headers = headers

  conf.url = url


  axios(conf)
    .then(response => {
      debug ? m_log(str_success, cGreen + cFont) : {};
      '/common/oauth/user' == url ? C.setCache('token', response.data.data) : {};
      '/user/profile' == url ? C.setCache('token', response.data.data) : {};
      '/user/profile/update' == url;
      success ? success(response.data) : m_log(response, cRed + cFont);
    })

    .catch(err => {
      debug ? m_log(str_error, cRed + cFont) : {};

      error ? error(err) : console.error(err);
    });
}
function chkEmptyObj(obj) {
  for (var key in obj) {
    return false;
  }
  return true;
}
function Req(api, params, s, e, m) {
  fetch(api, params, s, e, m);
}
export default {
	doPost(api, params, s, e) {
		Req(api, params, s, e, 'post');
	},
	doUpload(api, params, s, e) {
		Req(api, params, s, e, 'upload');
	},
	doGet(api, params, s, e) {
		Req(api, params, s, e, 'get');
	},
	isurl(str_url){
		var strRegex=/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/
		var re = new RegExp(strRegex);
		if (re.test(str_url)) {
			return (true);
		} else {
			return (false);
		}
	},
	IsURL(str_url) {
		var strRegex = "^((https|http|ftp|rtsp|mms)?://)"
			+ "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftpuser@ 
			+ "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IPURL- 199.194.52.184 
			+ "|" // IPDOMAIN（）
			+ "([0-9a-z_!~*'()-]+\.)*" // - www. 
			+ "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." //  
			+ "[a-z]{2,6})" // first level domain- .com or .museum 
			+ "(:[0-9]{1,4})?" // - :80 
			+ "((/?)|" // a slash isn't required if there is no file name 
			+ "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
		var re = new RegExp(strRegex);
		//re.test()
		if (re.test(str_url)) {
			return (true);
		} else {
			return (false);
		}
	}
}
