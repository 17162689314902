<!--  -->
<template>
  <div>
    <Header :showBack="true"></Header>
    <div class="content pb60">
      <div class="w1440">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player>
        <div class="mt30 fs36 fw600 col-0 lh50">{{ videodetail.name }}</div>
        <div class="mt10 fs16 col-3 lh22">{{ videodetail.createtime }}</div>
        <div class="mt10 fs18 col-6 lh26">
          {{ videodetail.miaoshu }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Header from '../components/Header';
// import { videoPlayer } from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
import api from '../api';
// import * as videojs from 'video.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Header },
  data () {
    //这里存放数据
    return {
      fileAreaHeight: 675,
      windowWidth: document.documentElement.clientWidth,
      videoWidth: 1200,
      fileType: 'mp4', // 资源的类型
      url: '',
      mp4Pic: '',
      videodetail: '',
    };
  },
  //监听属性 类似于data概念
  computed: {
    playerOptions () {
      const playerOptionsObj = {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //视频播放速度
        autoplay: false, // 如果true，浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
        // language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）。
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/' + this.fileType,	// 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
          src: this.url // 视频url地址
        }],
        poster: this.mp4Pic, // 视频封面地址
        width: this.videoWidth,
        // width: 1200,
        height: this.fileAreaHeight,	// 设置高度，fluid需要设置成flase
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true,  // 显示持续时间
          remainingTimeDisplay: false,  // 是否显示剩余时间功能
          fullscreenToggle: true  //全屏按钮
        }
      }
      return playerOptionsObj
    }
  },
  //监控data中的数据变化
  watch: {
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      if (val < 1200) {
        that.videoWidth = '100%';
      };
      if (val < 427) {
        that.fileAreaHeight = 400
      }
      if (that.windowWidth < 376) {
        that.fileAreaHeight = 300
      }
    }
  },
  //方法集合
  methods: {
    video () {
      api.doGet('/api/index/videodetail', {
        id: this.id
      }, (res) => {
        console.log(res);
        this.videodetail = res.data
        this.url = res.data.file
        this.mp4Pic = res.data.image

      })
    },
    checklogin () {
      console.log(window.localStorage.getItem('logintime'))

      console.log(window.localStorage.getItem('uid'))
      if (!window.localStorage.getItem('uid')) {
        this.$router.push('/login')
      }
      if (window.localStorage.getItem('logintime') + 86400 < Date.now()) {
        this.$router.push('/login')
      }

    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    var that = this;
    window.fullHeight = document.documentElement.clientHeight;
    window.fullWidth = document.documentElement.clientWidth;
    that.windowHeight = window.fullHeight;  // 高
    that.windowWidth = window.fullWidth; // 宽
    if (that.windowWidth < 427) {
      that.fileAreaHeight = 400
    }
    if (that.windowWidth < 376) {
      that.fileAreaHeight = 300
    }
    var id;
    this.id = this.$route.query.id
    console.log(this.id);
    this.video()
    this.checklogin()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight;  // 高
        that.windowWidth = window.fullWidth; // 宽
        if (that.windowWidth < 427) {
          that.fileAreaHeight = 400
        }
        if (that.windowWidth < 376) {
          that.fileAreaHeight = 300
        }
      })()
    };
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
// .content {
//   padding-top: 150px;
// }
.video-js .vjs-control-bar {
  width: 98.6%;
}
.lh50 {
  line-height: 50px;
}
</style>