<!--  -->
<template>
  <div>
    <!-- <Header v-model="result" :showBack="false"></Header> -->
    <div class="header_fix_box">
      <div class="header_fix">
        <div
          class="header_nav flex-between w1440"
          :class="[showBack ? 'flex flex-col-end' : 'flex-box']"
        >
          <img src="../assets/images/top_logo.png" @click="fanhui" class="top_logo" alt="" />
          <div class="flex-box back" v-if="showBack" @click="returnBack()">
            <div>Back</div>
          </div>
          <div class="head_inp flex-box" v-else>
            <img src="../assets/images/icon_search.png" @click="search" class="icon16" alt="" />
            <input
              type="text"
              class="inp_header flex-1 fs20"
              placeholder="please enter"
              @input="input_name($event.target.value)"
              confirm-type="search"
              @confirm="search"
              @keyup.enter="search"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content home_vue">
      <div class="w1440">
        <div class="item" v-for="(item, index) in list" :data-id="item.id" @click="godetail">
          <div class="cover_nav">
            <img :src="item.image" class="cover" alt="" />
            <div class="fixed">
              <div class="t1">{{ item.fenlei }}</div>
              <div class="btn">View Stories</div>
            </div>
          </div>
          <!-- <div class="mt20 fs18 col-6 lh26">
           {{ item.miaoshu }}
          </div> -->
        </div>
        <div class="tr" @click="yashuo">
          <div class="index_download_btn fs18">
            <div>Catalogue Download</div>
            <img src="../assets/images/icon_download_index.png" class="ico16 ml10" alt="" />
          </div>
        </div>
        <!-- <div class="item">
          <div class="cover_nav">
            <img src="../assets/images/cover1.png" class="cover" alt="" />
            <div class="fixed">
              <div class="t1">LIFESTYLE</div>
              <div class="btn">View Stories</div>
            </div>
          </div>
          <div class="mt20 fs18 col-6 lh26">
            Landing Page with KV / Right: After clicking through, you get to see 5 categories
            module, and each one has multiple stories presented by product & marketing teams.
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from '../api';
import Header from '../components/Header.vue';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Header
  },
  data () {
    //这里存放数据
    return {
      result: '',
      list: '',
      showBack: false,
      result: '',

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    input_name (e) {
      console.log(e);
      this.result = e;
      console.log(this.result);

    },
    fanhui () {
      location.reload();

    },
    search () {
      console.log('search')
      this.$router.push({ path: '/videolistview', query: { result: this.result } });
    },
    returnBack () {
      this.$router.back();
    },
    checklogin () {
      console.log(window.localStorage.getItem('logintime'))

      console.log(window.localStorage.getItem('uid'))
      if (!window.localStorage.getItem('uid')) {
        this.$router.push('/login')
      }
      if (window.localStorage.getItem('logintime') + 86400 < Date.now()) {
        this.$router.push('/login')
      }

    },
    videokind () {
      api.doGet('/api/index/kind', {
      }, (res) => {
        console.log(res);
        this.list = res.data
        if (this.list == '') {
          alert('No relevant classification')
        }
      })
    },
    yashuo(){
      var name = 'haiwai.rar'
      fetch('https://k-xilukeji.oss-cn-shanghai.aliyuncs.com/websites/20231103/95d7d3417188f6455f44b0233e5e9a4c.rar')
        .then(response => response.blob()) // 将响应转化为 Blob 对象  
        .then(blob => {
          // 创建一个隐藏的 <a> 标签  
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 将 Blob 对象转化为 URL  
          link.download = name; // 设置下载文件的名称  
          document.body.appendChild(link); // 将 <a> 标签添加到页面中  
          link.click(); // 模拟用户点击下载链接  
        })
        .catch(error => console.error(error)); // 处理错误情况
    },
    godetail (e) {
      var id = e.currentTarget.dataset.id
      console.log(id)
      this.$router.push({ path: '/videolistview', query: { id: id } });

    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // window.localStorage.getItem('logintime')='1608532451'
    this.checklogin()
    this.videokind()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>