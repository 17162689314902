<!--  -->
<template>
  <div>
    <Header :showBack="true"></Header>
    <div class="content video_list">
      <div class="w1440">
        <div class="cover_nav">
          <img :src="kind.image" class="cover" alt="" />
          <div class="fixed">
            <div class="t1">{{ kind.fenlei }}{{ select }}</div>
          </div>
        </div>
        <div class="sqe"></div>
        <div v-if="select==true">
          <img src="../assets/images/null.png" class="null" alt="" />
          <div class="col-89 fs34 tc">没找到您要的内容!</div>
        </div>
        <div v-if="list.length % 3 == 0 && list.length != 0">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            :data-id="item.id"
            @click="godetail"
          >
            <div class="video_cover_nav" @click="videodetail" :data-id="item.id">
              <img :src="item.image" class="video_cover" alt="" />
              <img src="../assets/images/play.png" class="play" alt="" />
              <div class="time_nav">{{ item.shichang }}</div>
            </div>
            <div class="mt10 fs18 fw600 col-0 lh26 h52 m-ellipsis-l2">{{ item.name }}</div>
            <div class="mt15 flex-box flex-between">
              <div class="fs16 lh22 col-6">
                <!-- {{ item.createtime }} -->
              </div>
              <!-- <a :href="item.file" :download="item.name" >  -->
              <button
                class="btn_download fs16 col-2 flex-box"
                @click="downloadVideo"
                :data-file="item.file"
                :data-name="item.name"
              >
                <div>download</div>
                <img src="../assets/images/icon_download.png" class="icon_download ml10" alt="" />
              </button>
              <!-- </a> -->
            </div>
          </div>
        </div>
        <div v-if="list.length % 3 != 0">
          <div
            class="item_mid"
            v-for="(item, index) in list"
            :key="index"
            :data-id="item.id"
            @click="godetail"
          >
            <div class="video_cover_nav" @click="videodetail" :data-id="item.id">
              <img :src="item.image" class="video_cover" alt="" />
              <img src="../assets/images/play.png" class="play" alt="" />
              <div class="time_nav">{{ item.shichang }}</div>
            </div>
            <div class="mt10 fs18 fw600 col-0 lh26 h52 m-ellipsis-l2">{{ item.name }}</div>
            <div class="mt15 flex-box flex-between">
              <div class="fs16 lh22 col-6">
                <!-- {{ item.createtime }} -->
              </div>
              <!-- <a :href="item.file" :download="item.name" >  -->
              <button
                class="btn_download fs16 col-2 flex-box"
                @click="downloadVideo"
                :data-file="item.file"
                :data-name="item.name"
              >
                <div>download</div>
                <img src="../assets/images/icon_download.png" class="icon_download ml10" alt="" />
              </button>
              <!-- </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Header from '../components/Header';
import api from '../api';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Header
  },
  data () {
    //这里存放数据
    return {
      isNine: false,
      isEight: true,
      id: '',
      list: '',
      kind: '',
      result: '',
      length: '',
      select:false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    videolist () {
      if (this.result != '') {
        api.doGet('/api/index/detail', {
          id: this.id,
          result: this.result
        }, (res) => {
          console.log(res)
          console.log(res.code)
          if (res.code == 0) {
            // console.log(1111111111)
            this.list = res.data
            this.length = 1
           
            // this.select=true
           
          } else {
            // console.log(222222222222);
       
            this.select=true
          }
        })
      } else {
        api.doGet('/api/index/detail', {
          id: this.id,
          result: this.result
        }, (res) => {
          console.log(res);
          if (res.code == 0) {
            // console.log(33333333333);
            // this.select=true
            this.list = res.data
            this.length = 1
          } else {
            this.list = ''
            this.list.length = 0
          }  this.select=true
          // console.log(444444444444444);


        })
      }
    },
    kinddetail () {
      api.doGet('/api/index/kinddetail', {
        id: this.id
      }, (res) => {
        console.log(res);
        this.kind = res.data

      })
    },
    checklogin () {
      console.log(window.localStorage.getItem('logintime'))

      console.log(window.localStorage.getItem('uid'))
      if (!window.localStorage.getItem('uid')) {
        this.$router.push('/login')
      }
      if (window.localStorage.getItem('logintime') + 86400 < Date.now()) {
        this.$router.push('/login')
      }

    },
    godetail () {

    },
    downloadVideo (e) {
      // e.preventDefault();  
      // const link = document.createElement('a');  
      // link.href = e.currentTarget.dataset.file
      // link.download = 'a.mp4';
      // link.click();  
      // console.log(link.click());
      var name = e.currentTarget.dataset.name
      fetch(e.currentTarget.dataset.file)
        .then(response => response.blob()) // 将响应转化为 Blob 对象  
        .then(blob => {
          // 创建一个隐藏的 <a> 标签  
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob); // 将 Blob 对象转化为 URL  
          link.download = name; // 设置下载文件的名称  
          document.body.appendChild(link); // 将 <a> 标签添加到页面中  
          link.click(); // 模拟用户点击下载链接  
        })
        .catch(error => console.error(error)); // 处理错误情况

    },
    videodetail (e) {
      console.log(e);
      console.log(e.currentTarget.dataset.id);
      this.id = e.currentTarget.dataset.id
      this.$router.push({ path: '/videoinfo', query: { id: this.id } });
    },


  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    var id;
    this.id = this.$route.query.id
    console.log(this.id);
    this.result = this.$route.query.result
    console.log(this.id);
    this.checklogin()
    this.kinddetail()
    this.videolist()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类

.sqe {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  height: 1px;
  background: #eeeeee;
}
.video_list {
  padding-bottom: 50px;
}
.null {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 30vw;
  height: auto;
  margin-bottom: 10px;
}
</style>