<template>
  <div class="content bg_img">
    <div v-if="mobile"></div>
    <img src="../assets/images/role2.png" class="img_role" alt="" v-else />
    <div class="w1440 flex-box flex-end pr z2" style="height: 100vh">
      <div class="login_right">
        <img src="../assets/images/logo_moblie.png" class="logo_mini" alt="" v-if="mobile" />
        <img src="../assets/images/logo.png" class="logo" alt="" v-else />
        <!-- <div class="box_vi"></div> -->
        <div class="box login_new">
          <div class="login_title">Log In</div>
          <div class="login_subtitle">Welcome to FA24 GTM3</div>
          <div class="mt50 flex-box">
            <img src="../assets/images/icon_email.png" class="icon18" alt="" />
            <div class="fs16 fw600 col-f lh16 ml5">Mailbox</div>
          </div>
          <div class="inp_nav mt20 flex-box">
            <input
              type="text"
              @blur="account"
              placeholder="Please enter your email address"
              class="flex-1 plr30 fs16 col-0 m-inp"
            />
          </div>
          <div class="mt30 flex-box">
            <img src="../assets/images/icon_pwd.png" class="icon18" alt="" />
            <div class="fs16 fw600 col-f lh16 ml5">Passcode</div>
          </div>
          <div class="inp_nav mt20 flex-box">
            <input
              type="password"
              @blur="password"
              placeholder="Please enter the password"
              class="flex-1 plr30 fs16 col-0 m-inp"
            />
          </div>
          <div class="g-btn1 mt50" @click="login">Login</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


import api from '../api';
import { Message } from "element-ui";
export default {
  //import引入的组件需要注入到对象中才能使用
  name: 'HomeView',
  components: {
  },
  data () {
    //这里存放数据
    return {
      result: '',
      youxiang: '',
      passworda: '',
      popVis: false,
      windowWidth: document.documentElement.clientWidth,
      mobile: false
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      if (val < 1200) {
        that.videoWidth = '100%';
      };
      if (val < 427) {
        that.mobile = true
      }

    }
  },
  //方法集合
  methods: {
    account (e) {
      var youxiang = e.target.value.trim()
      if (youxiang) {
        this.youxiang = youxiang
      }
    },
    password (e) {
      var passworda = e.target.value.trim()
      if (passworda) {
        this.passworda = passworda
      }
    },
    login () {
      // this.popVis = !this.popVis;
      //  Message({
      //     message: 11111111111111111,
      //     type: "error"
      //   });
      api.doGet('/api/index/login', {
        account: this.youxiang,
        password: this.passworda
      }, (res) => {
        console.log(res);
        if (res.code != 0) {
          alert(res.msg)
        } else {
          window.localStorage.setItem('uid', res.data.id)
          window.localStorage.setItem('logintime', res.data.logintime)
          this.$router.replace('/')
        }
      })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    var that = this;
    window.fullHeight = document.documentElement.clientHeight;
    window.fullWidth = document.documentElement.clientWidth;
    that.windowHeight = window.fullHeight;  // 高
    that.windowWidth = window.fullWidth; // 宽
    if (that.windowWidth < 427) {
      that.mobile = true
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight;  // 高
        that.windowWidth = window.fullWidth; // 宽
        if (that.windowWidth < 427) {
          that.mobile = true
        }

      })()
    };
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
