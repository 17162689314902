<!-- 头部组件 -->
<template>
  <div class="header_fix_box">
    <div class="header_fix">
      <div
        class="header_nav flex-between w1440"
        :class="[showBack ? 'flex flex-col-end' : 'flex-box']"
      >
        <img src="../assets/images/top_logo.png" @click="fanhui" class="top_logo" alt="" />
        <div class="flex-box back" v-if="showBack" @click="returnBack()">
          <img src="../assets/images//arrow_back.png" class="icon16 mr10" alt="">
          <div>Back</div>
        </div>
        <div class="head_inp flex-box" v-else>
          <img src="../assets/images/icon_search.png" class="icon16" alt="" />
          <input
            type="text"
            class="inp_header flex-1 fs20"
            placeholder="please enter"
            @input="input_name($event.target.value)"
            confirm-type="search" @confirm="search"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: ['value', 'showBack'],
  data () {
    //这里存放数据
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    input_name (e) {
      console.log(e);
      this.$emit("input", e);
    },
    fanhui(){
      this.$router.push('/')
    },
    search(){
     console.log('search')
    },
    returnBack () {
      this.$router.back();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// .header_fix {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 999;
//   height: 150px;
//   padding-top: 30px;
//   background: #fff;
//   .header_nav {
//     height: 90px;
//     max-width: 1200px;
//   }
//   .top_logo {
//     width: 222px;
//     height: auto;
//   }
//   .head_inp {
//     width: 190px;
//     height: 60px;
//     border-radius: 10px;
//     padding: 0 15px;
//     border: 2px solid #838aa1;
//     .inp_header {
//       padding-left: 10px;
//       color: #997be6;
//     }
//     .inp_header::placeholder {
//       color: #997be6;
//     }
//   }
// }
// .back {
//   font-size: 18px;
//   font-weight: 600;
//   color: #997be6;
//   cursor: pointer;
// }
</style>